var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      key: _vm.session.id,
      class: {
        grouped: _vm.in_group,
        tracking: _vm.is_tracking,
        header: _vm.header,
        [_vm.theme]: true,
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _vm.show_rounded_time
          ? _c("span", { staticClass: "start-stop rounded" }, [
              _c("strong", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("milli2date")(
                        (_vm.start || _vm.session.start).seconds * 1000
                      )
                    ) +
                    " "
                ),
              ]),
              _vm._v(" - "),
              _vm.stop && _vm.session.stop
                ? _c("strong", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("milli2date")(
                            (_vm.stop || _vm.session.stop).seconds * 1000
                          )
                        ) +
                        " "
                    ),
                  ])
                : _c("strong", [_vm._v("Now")]),
            ])
          : _vm._e(),
        _c("span", { staticClass: "start-stop real" }, [
          _c("strong", [
            _vm._v(
              " " +
                _vm._s(_vm._f("milli2date")(_vm.session.start.seconds * 1000)) +
                " "
            ),
          ]),
          _vm._v(" - "),
          _vm.session.stop
            ? _c("strong", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("milli2date")(_vm.session.stop.seconds * 1000)
                    ) +
                    " "
                ),
              ])
            : _c("strong", [_vm._v("Now")]),
        ]),
        _vm.show_rounded_time
          ? _c("span", { staticClass: "duration rounded" }, [
              _vm._v(
                " " + _vm._s(_vm._f("milli2duration")(_vm.duration)) + " "
              ),
            ])
          : _vm._e(),
        _c("span", { staticClass: "duration real" }, [
          _vm._v(
            " " + _vm._s(_vm._f("milli2duration")(_vm.real_duration)) + " "
          ),
        ]),
      ]),
      _c("session-input", {
        ref: "editor",
        staticClass: "el-input session-input",
        attrs: {
          placeholder: _vm.input_placeholder,
          session: _vm.session,
          simple_responsive_design: true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }